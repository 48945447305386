<template>
    <ValidationObserver ref="validator" slim>
        <v-form
            class="user-edit"
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-toolbar flat>
                <v-toolbar-title class="link" @click="auth(entity._id, entity.phone)">
                    {{ $t('src\\views\\users\\edit.456') }}
                    <svg
                        class="list-icon-primary"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24">
                        <path d="M18 16H14V18H18V20L21 17L18 14V16M11 4C8.8 4 7 5.8 7 8S8.8 12 11 12 15 10.2 15 8 13.2 4 11 4M11 14C6.6 14 3 15.8 3 18V20H12.5C12.2 19.2 12 18.4 12 17.5C12 16.3 12.3 15.2 12.9 14.1C12.3 14.1 11.7 14 11 14" />
                    </svg>
                </v-toolbar-title>
            </v-toolbar>
            <v-switch
                v-model="entity.admin"
                :label="$t('src\\views\\users\\edit.7511')"
            />
            <v-row v-if="entity.admin">
                <template v-for="adminAccess in adminAccessArray">
                    <v-col :key="`adminAccess-${adminAccess}`" cols="3">
                        <v-select
                            v-model="entity.adminAccess[adminAccess]"
                            :label="adminAccessTitle[adminAccess]"
                            :items="accessArray"
                            item-text="title"
                            item-value="value"
                            outlined
                            hide-details
                        />
                    </v-col>
                </template>
            </v-row>
                
            <v-switch
                v-model="entity.organizer"
                :label="$t('src\\views\\users\\edit.1413')"
            />
            <v-row>
                <v-col cols="3">
                    <v-switch
                        v-model="entity.blogger"
                        :label="$t('src\\views\\users\\edit.821')"
                    />
                </v-col>
                <v-col>
                    <v-text-field
                        v-if="entity.blogger"
                        v-model="entity.qualifiedQuizCount"
                        :label="$t('src\\views\\users\\edit.4552')"
                        outlined
                    />
                </v-col>
                <v-col>
                    <v-text-field
                        v-if="entity.blogger"
                        v-model="entity.qualifiedQuizCountAdvanced"
                        :label="$t('src\\views\\users\\edit.1025')"
                        outlined
                    />
                </v-col>
                <v-col>
                    <v-text-field
                        v-if="entity.blogger"
                        v-model="entity.qualifiedQuizCountExpert"
                        :label="$t('src\\views\\users\\edit.4645')"
                        outlined
                    />
                </v-col>
            </v-row>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.firstname"
                    :label="$t('src\\views\\users\\edit.6934')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider>
                <v-text-field
                    v-model="entity.lastname"
                    :label="$t('src\\views\\users\\edit.1721')"
                    outlined
                />
            </ValidationProvider>
            <v-img :src="imageSrc(entity.avatar)" />
            <v-file-input
                :label="$t('src\\views\\users\\edit.1935')"
                outlined
                prepend-icon="mdi-image"
                @change="setPicture"
            />
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.phone"
                    :label="$t('src\\views\\users\\edit.8010')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <v-text-field
                v-model="entity.email"
                :label="$t('src\\views\\users\\edit.email')"
                outlined
            />
            <v-text-field
                v-model="entity.birthday"
                :label="$t('src\\views\\users\\edit.3210')"
                outlined
            />
            <v-text-field
                v-model="entity.company"
                :label="$t('src\\views\\users\\edit.5078')"
                outlined
            />
            <v-text-field
                v-model="entity.site"
                :label="$t('src\\views\\users\\edit.4212')"
                outlined
            />
            <v-text-field
                v-model="entity.social"
                :label="$t('src\\views\\users\\edit.3694')"
                outlined
            />
            <v-textarea
                v-model="entity.about"
                :label="$t('src\\views\\users\\edit.2716')"
                auto-grow
                outlined
            />

            <v-row
                align="center"
                justify="space-between"
                class="mx-0">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save">{{ $t('src\\views\\users\\edit.5731') }}</v-btn>
                <v-btn
                    v-if="entity._id"
                    color="error"
                    @click="remove">{{ $t('src\\views\\users\\edit.989') }}</v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { imageSrc, generateCode } from '../../helpers';

    export default {
        name: 'UsersEdit',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            imageSrc,
            adminAccessArray: [
                'trips',
                'bookings',
                'payments',
                'documents',
                'reviews',
                'news',
                'reports',
                'feedbacks',
                'quizes',
                'waiting_lists',
                'users',
                'trip_requests',
                'blogger_trips',
                'blogger_resources',
                'dictionaries',
                'quiz_dictionaries',
                'page_templates',
                'booking_settings',
                'server_tasks',
                'translates',
                'event_templates',
            ],
            accessArray: [
                { title: 'NONE', value: 0 },
                { title: 'READ', value: 1 },
                { title: 'WRITE', value: 2 },
                { title: 'FULL', value: 3 },
            ],
            adminAccessTitle: {},
            valid: true
        }),
        computed: {
            ...mapState('users', ['entity']),
        },
        async mounted() {
            if(this.$route.params.id) {
                await store.dispatch('users/get', { id: this.$route.params.id });
            } else {
                await store.commit('users/CLEAR_ENTITY');
            }
            this.adminAccessTitle = {
                trips: this.$t('src\\app.4559'),
                bookings: this.$t('src\\app.5031'),
                payments: this.$t('src\\app.8651'),
                documents: this.$t('src\\app.1735'),
                reviews: this.$t('src\\app.4940'),
                news: this.$t('src\\app.5731'),
                reports: this.$t('src\\app.reports'),
                feedbacks: this.$t('src\\app.553'),
                quizes: this.$t('src\\app.7721'),
                waiting_lists: this.$t('src\\app.7722'),
                users: this.$t('src\\app.8162'),
                trip_requests: this.$t('src\\app.3851'),
                blogger_trips: this.$t('src\\app.9808'),
                blogger_resources: 'Ресурсы блогеров',
                dictionaries: this.$t('src\\app.5927'),
                quiz_dictionaries: this.$t('src\\app.9364'),
                page_templates: this.$t('src\\app.3811'),
                booking_settings: this.$t('src\\app.951'),
                server_tasks: this.$t('src\\app.1703'),
                translates: this.$t('src\\app.919'),
                event_templates: this.$t('src\\app.4107'),
            };
        },
        methods: {
            async setPicture(file) {
                this.entity.avatar = await store.dispatch('files/upload', { file });
            },
            async save() {
                this.valid = await this.$refs.validator.validate();
                if(this.valid){
                    await store.commit('users/SET_ENTITY', this.entity);
                    await store.dispatch('users/save');
                    await this.$router.push({ name: 'users-list' });
                }
            },
            async remove() {
                await store.dispatch('users/delete', { id: this.entity._id });
                await this.$router.push({ name: 'users-list' });
            },
            async auth(id, phone) {
                const code = generateCode(8);
                await store.dispatch('users/setAdminCode', { id, code });
                window.open(process.env.VUE_APP_FRONT_URL + `/?action=auth&phone=${phone}&code=${code}`, '_blank');
            }
        }
    }
</script>

<style lang="scss">
    .user-edit {
        .link {
            display: flex;
            align-items: center;
            cursor: pointer;

            & > svg {
                margin-left: 5px;
            }
        }
    }
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
</style>
